import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Main from '../layouts/Main';
import Container from '../components/Container';
import Content from '../components/MultiPlatformExperiences/Content';
import Footer from '../components/MultiPlatformExperiences/Footer'
import Headline from '../components/MultiPlatformExperiences/Headline';
import {OntoNavbar} from "../components/OntoNavbar";

const MultiPlatformExperiences = (): JSX.Element => {
    const theme = useTheme();
    return (
        <Main>
            <OntoNavbar></OntoNavbar>
            <Box paddingTop={5}>
                <Box
                    sx={{
                        backgroundColor: theme.palette.alternate.main,
                        backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
                        marginTop: -13,
                        paddingTop: 13,
                    }}
                >
                    <Container>
                        <Headline />
                    </Container>
                </Box>
                <Container maxWidth={800}>
                    <Content />
                </Container>
                <Box bgcolor={theme.palette.alternate.main}>
                    <Container>
                        <Footer />
                    </Container>
                </Box>
            </Box>
        </Main>
    );
};

export default MultiPlatformExperiences;