/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const Footer = (): JSX.Element => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <Box>
            <Box marginBottom={4}>
                <Typography
                    variant="h4"
                    align={'center'}
                    sx={{
                        fontWeight: 'medium',
                    }}
                    paddingBottom={2}
                >
                    Didn't find what you are looking for?
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 'medium',
                    }}
                    gutterBottom
                    color={'textSecondary'}
                    align={'center'}
                >
                    Get in touch to see if our experience aligns with your goals.
                </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent={'center'}
                alignItems={{ xs: 'stretched', sm: 'center' }}
            >
                <Button variant="contained" color="primary" size="large" href="/#contact">
                    Contact Us
                </Button>
            </Box>
        </Box>
    );
};

export default Footer;