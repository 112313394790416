import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Headline = (): JSX.Element => {
    return (
        <Box>
            <Typography
                sx={{
                    textTransform: 'uppercase',
                    fontWeight: 'medium',
                }}
                gutterBottom
                color={'textSecondary'}
                align={'center'}
            >
                Multi-Platform Experience
            </Typography>
            <Typography variant="h2" align={'center'} fontWeight={700} gutterBottom>
                Our Team's Expertise
            </Typography>
            <Typography variant="h6" align={'center'} color={'textSecondary'}>
                Explore our team's knowledge and specialties.
            </Typography>
        </Box>
    );
};

export default Headline;