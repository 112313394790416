import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface FaqGroupItemProps {
    title: string;
    items: Array<{
        title: string;
        subtitle: string;
    }>;
}

const FaqGroupItem = ({ title, items }: FaqGroupItemProps): JSX.Element => {
    const theme = useTheme();
    return (
        <Box>
            <Box marginBottom={2}>
                <Typography fontWeight={700} variant={'h5'}>
                    {title}
                </Typography>
            </Box>
            <Box>
                {items.map((item, i) => (
                    <Box
                        component={Accordion}
                        key={i}
                        padding={1}
                        marginBottom={2}
                        borderRadius={`${theme.spacing(1)} !important`}
                        sx={{
                            '&::before': {
                                display: 'none',
                            },
                        }}
                    >
                        <Box
                            component={AccordionSummary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id={`panel1a-header--${i}`}
                        >
                            <Typography fontWeight={600}>{item.title}</Typography>
                        </Box>
                        <AccordionDetails>
                            <Typography color="text.secondary">{item.subtitle}</Typography>
                        </AccordionDetails>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

const Content = (): JSX.Element => {
    return (
        <Box>
            <Box marginBottom={6}>
                <FaqGroupItem
                    title={'Languages & Platforms'}
                    items={[
                        {
                            title: 'Programming Languages',
                            subtitle:
                                'Our team has experience with a variety of programming languages, including Swift, Kotlin, Objective-C, JavaScript, TypeScript, HTML, CSS, Scala, and Java.',
                        },
                        {
                            title: 'Platforms',
                            subtitle:
                                'Our team members are skilled in a variety of areas of development, including web, iOS, and Android application development.',
                        },
                    ]}
                />
            </Box>
            <Box marginBottom={6}>
                <FaqGroupItem
                    title={'Frameworks, Libraries & Services'}
                    items={[
                        {
                            title: 'Frameworks',
                            subtitle:
                                'Our team has utilized various frameworks to improve the user experience, including Gatsby and Remix.',
                        },
                        {
                            title: 'Libraries',
                            subtitle:
                                'We often make use of libraries to efficiently provide a high quality user interface. Libraries we have worked with include React and Material UI.',
                        },
                        {
                            title: 'Chime',
                            subtitle:
                                'Our team has experience utilizing the Amazon Chime service to create custom multi-party video conferencing and chat features.',
                        },
                    ]}
                />
            </Box>
            <Box>
                <FaqGroupItem
                    title={'Amazon Web Services'}
                    items={[
                        {
                            title: 'Compute',
                            subtitle:
                                'AWS Lambda',
                        },
                        {
                            title: 'Front-End Web & Mobile',
                            subtitle:
                                'AWS AppSync',
                        },
                        {
                            title: 'Management & Governance',
                            subtitle:
                                'AWS CloudTrail, Amazon CloudWatch',
                        },
                        {
                            title: 'Application Integration',
                            subtitle:
                                'AWS Step Functions, Amazon EventBridge, Amazon Simple Queue Service (SQS), Amazon Simple Notification Service (SNS)',
                        },
                        {
                            title: 'Networking & Content Delivery',
                            subtitle:
                                'Amazon API Gateway',
                        },
                        {
                            title: 'Storage',
                            subtitle:
                                'Amazon Simple Storage Service (S3)',
                        },
                        {
                            title: 'Database',
                            subtitle:
                                'Amazon DynamoDB',
                        },
                        {
                            title: 'Analytics',
                            subtitle:
                                'AWS Glue, Amazon OpenSearch Service, Amazon Quicksight',
                        },
                    ]}
                />
            </Box>
        </Box>
    );
};

export default Content;